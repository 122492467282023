<template>
  <div class="bg">
    <van-search
      v-model="value"
      placeholder="请输入搜索关键词"
      input-align="center"
      left-icon = 'none'
      @search="onSearch"
    />
    <van-tabs v-model="active" sticky animated class="mytab">
      <van-tab title="全部医生">
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
          >
            <div v-for="item in list" @click="godoctorinfo" class="mylist" :key="item">
              <div class="listtop">
                <div class="cl">
                  <img src="https://img.yzcdn.cn/vant/apple-1.jpg" class="fl" alt="" />
                  <div class="fl">
                    <p>王林 主任医生</p>
                    <p class="em sl1">擅长领域：慢性咳嗽、哮喘、支气管...</p>
                    <p><span>6人预约中</span></p>
                  </div>
                </div>
                <div class="top_right">
                  <span @click.stop="gonav('appointment')">预约</span>

                  <!-- <span @click.stop="gonav('subsequent')">复诊</span> -->
                </div>
              </div>
            </div>
          </van-list>
        </van-pull-refresh>
      </van-tab>
      <van-tab title="在线医生">
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
          >
            <div v-for="item in list" @click="godoctorinfo" class="mylist" :key="item">
              <div class="listtop">
                <div class="cl">
                  <img src="https://img.yzcdn.cn/vant/apple-1.jpg" class="fl" alt="" />
                  <div class="fl">
                    <p>王林 主任医生</p>
                    <p class="em sl1">擅长领域：慢性咳嗽、哮喘、支气管...</p>
                    <p><span>6人预约中</span></p>
                  </div>
                </div>
                <div class="top_right">
                  <span @click.stop="appointment">预约</span>
                </div>
              </div>
            </div>
          </van-list>
        </van-pull-refresh>
      </van-tab>
    </van-tabs>
  </div>
</template>
<script>
export default {
  data() {
    return {
      value: "",
      active: "",
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
    };
  },
  methods: {
    gonav(url) {
      this.$router.push({ name: url });
    },
    // 预约
    appointment() {
      this.$router.push({ name: "appointment" });
    },
    godoctorinfo() {
      this.$router.push({ name: "doctoruser" });
    },
    onLoad() {
      setTimeout(() => {
        if (this.refreshing) {
          this.list = [];
          this.refreshing = false;
        }

        for (let i = 0; i < 10; i++) {
          this.list.push(this.list.length + 1);
        }
        this.loading = false;

        if (this.list.length >= 40) {
          this.finished = true;
        }
      }, 1000);
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.onLoad();
    },
    onSearch() {
      console.log("eeee");
    },
  },
};
</script>

<style scoped>
.bg {
  /* height: 667px;
  background: #f5f5f5; */
  /* padding: 0 16px; */
}
/deep/.van-sticky { background: #fff;}
/deep/.van-tabs__wrap { width: 176px; margin: 0 auto; border-radius: 20px; background: #E8F7FF;} 
/deep/.mytab .van-tabs__nav { background: #E8F7FF;} 
/deep/.van-search__content { border-radius: 18px;}
/deep/.mytab .van-tab--active .van-tab__text {
  color: rgba(255, 255, 255, 1);
}
/deep/.mytab .van-tab--active{
  border: none;
  box-sizing: border-box;
  border-radius: 22px;
}

/deep/.mytab .van-tab {
  /* border: 1px solid #ff0; */
  width: 88px;
  box-sizing: border-box;
   border-radius: 22px;
}
/deep/.mytab .van-tab__text {
  z-index: 10;
  color: #444444;
  font-size: 14px;
}
/deep/.mytab .van-tabs__line {
  width: 88px;
  height: 44px;
  border-radius: 22px;
  background: #1daefc;
}
</style>
<style lang="less" scoped>
.mylist {
  .listbot {
    font-size: 14px;
    text-align: left;
    margin-top: 15px;
  }
  .top_right {
    text-align: center;
    span {
      margin-top: 13px;
      display: inline-block;
      line-height: 32px;
      color: #1daefc;
      width: 44px;
      height: 32px;
      border: 1px solid #1daefc;
      border-radius: 4px;
    }
    .em {
      margin-right: 15px;
    }
    .zt {
      font-size: 14px;
      color: rgba(29, 174, 252, 1);
    }
    .zt.on {
      font-size: 14px;
      color: #d8d8d8;
    }
  }
  width: 100%;
  // width: 309px;
  background: #ffffff;
  // height: 154px;
  margin-bottom: 10px;
  box-sizing: border-box;
  padding: 16px;
  border-bottom: 1px solid #efefef;
  .listtop {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    p {
      text-align: left;
      margin-bottom: 5px;
      span {
        font-size: 12px;
        color: #1daefc;
      }
    }
    img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      margin-right: 8px;
    }
  }
}
.mytab {
  width: 375px;
  height: 48px;
  box-sizing: border-box;
  .van-tab--active {
    .van-tab__text {
      color: rgba(255, 255, 255, 1);
    }
    .van-tab {
      border: none;
      box-sizing: border-box;
    }
  }
  .van-tab {
    border: 1px solid #d8d8d8;
    box-sizing: border-box;
  }
  .van-tab__text {
    z-index: 10;
    color: rgba(0, 0, 0, 1);
    font-size: 14px;
  }
  .van-tabs__line {
    width: 50%;
    height: 100%;
    background: #1daefc;
  }
}
</style>
